<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>CASE Item identifiers (GUIDs)</h3>

	<p>One of the most important pieces of CASE data stored as part of every framework item is the item’s <i>identifier</i>.</p>
	<ul>
		<li>CASE specifies that identifiers should more specifically take the form of <a href="https://en.wikipedia.org/wiki/Universally_unique_identifier" target="_blank">Globally Unique IDentifiers</a> (GUIDs; also called “Universally Unique IDentifiers”, or UUIDs), which are strings of 16 hexidecimal characters (0123456789abcdef) in the format 8-4-4-4-12.</li>
		<li>An example GUID is: <code>ec06bbed-ab66-4bc1-b38b-b39664fe0365</code></li>
		<li>GUIDs are generated in such a way that every GUID is, for practical purposes, “globally” unique. This means that no two CASE standards will have the same GUID, even if the standards were created in different CASE authoring systems.</li>
		<li>These unique identifiers are one of the most important factors by which CASE facilitates system interoperability. Here’s an example to illustrate this:<ul>
			<li>One of Georgia’s math standards is designated by the GUID <code>487b928c-f66e-4fb9-97d3-92d912c53c5b</code>.</li>
			<li>System A, an assessment engine, can “tag” a test question to this standard simply by recording the standard’s GUID as metadata on the test question’s database record.</li>
			<li>System B, a video resource repository, can also tag a video to this standard by recording the same GUID as metadata on the video record.</li>
			<li>System C, a learning management system, can then:<ul>
				<li>Send a student to system A to take a test and receive back a message that the student missed the question tagged to standard <code>487b928c-f66e-4fb9-97d3-92d912c53c5b</code>,</li>
				<li>show a report with the text of the standard, retrievable from the CASE framework via the GUID <code>487b928c-f66e-4fb9-97d3-92d912c53c5b</code>,</li>
				<li>send to system B a request to serve the student a video tagged to standard <code>487b928c-f66e-4fb9-97d3-92d912c53c5b</code>,</li>
				<li>and so on.</li>
			</ul></li>
		</ul></li>
		<li>In these help documents, and in common parlance when discussing CASE, the terms “identifier”, “GUID”, and “UUID” are for practical purposes synonymous.</li>
		<li>In addition to CASE items, other entities such as documents and associations are also identified by GUIDs.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	